import React from "react";
import ReactDOM from "react-dom";
import {WhiteLabelFooter} from "../src/features/rb_modern_footer/white_label_footer";

document.addEventListener('DOMContentLoaded', () => {
    const isWhiteLabel = $("body").attr('data-white-label') === "true";
    if (isWhiteLabel) {
        ReactDOM.render(
            <WhiteLabelFooter/>,
            document.getElementById('rb__footer'),
        );
    }
});