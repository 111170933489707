import React from "react";
import {imgUrl} from "../../../services/constants";

export function PoweredByRB() {

    return (
        <div className="powered-by-wrapper">
            <div className="powered-by-holder">
                <img src={`${imgUrl}/rb_hero.png`} className={"powered-by-rb-hero"}/>
            </div>
            <div className="powered-by-holder">
                <div className="powered-by">Powered by</div>
                <div className="rb-stretch">Rental
                    <span className="beast-text">Beast</span>
                </div>
            </div>
        </div>
    )
}