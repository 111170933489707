import React, {useEffect, useState} from "react";
import "./white_label_footer.scss";
import {createUseStyles} from "react-jss";
import {PartnerConfig} from "../rb_modern_header/white_label_header";
import {FooterTemplate} from "./FooterTemplate";

const useFooterStyles = createUseStyles({
    main: props => ({
        backgroundColor: props?.main.backgroundColor || 'white',
        color: props?.main.color || 'black',
        '& a': {
            color: props?.main.linkColor || 'black'
        },
    }),
    legal: props => ({
        backgroundColor: props?.legal.backgroundColor || 'white',
        color: props?.legal.color || 'black',
        '& a': {
            color: props?.main.linkColor || 'black'
        },
    })
});

export function WhiteLabelFooter() {
    const [partnerConfig, setPartnerConfig] = useState(null as PartnerConfig);
    const styles = useFooterStyles(partnerConfig?.bp_ui_settings.styles.bpWlFooter);

    const setupFooterSettings = () => {
        const partnerConfigRes = $("body").attr('data-partner-config');
        const partnerConfigJson = JSON.parse(partnerConfigRes).gui_settings as PartnerConfig;
        setPartnerConfig(partnerConfigJson);
    };

    useEffect(() => {
        setupFooterSettings();
    }, []);

    return <div>{partnerConfig?.bp_ui_settings?.content ? <FooterTemplate styles={styles} content={partnerConfig?.bp_ui_settings}/> : null}</div>
}